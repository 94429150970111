import * as React from "react"
// import Slider from "react-slick";
import { Autoplay, FreeMode } from 'swiper';
import {
  Swiper as Slider,
  SwiperSlide as Slide,
} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/grid';

const LogosSlider = ({ items = [] }) => (
  <Slider
    autoplay={{
      delay: 1,
    }}
    breakpoints={{
      1366: {
        slidesPerView: 5,
      },
      1280: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      767: {
        slidesPerView: 2,
      },
      360: {
        slidesPerView: 1,
      },
    }}
    className="w-full mb-5 carriers-blk-slider1"
    direction="horizontal"
    freeMode={{
      momentum: false,
    }}
    loop
    modules={[Autoplay, FreeMode]}
    spaceBetween={20}
    speed={5000}
  >
    {items.map(({ logo }) => (
      <Slide>
        <div className="p-2 logo-item rounded-md justify-center items-center bg-white flex my-1 h-10 w-max-sm">
          <img
            loading="lazy"
            className="max-h-10 mb-0"
            alt={logo.alt}
            src={logo.fluid.srcSet}
            srcSet={logo.fluid.srcSetWebp}
          />
        </div>
      </Slide>
    ))}
  </Slider>
);

export default LogosSlider;
