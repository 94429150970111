import React from "react"
import { graphql } from "gatsby"
import { chunk } from "lodash"

import LogosSlider from "../Elements/LogosSlider";

import CarrierListContent from './CarrierListContent';

const chunks = 4;

const CarrierListHero = ({
  slice: {
    primary: {
      heading,
      sub_heading,
    },
    items,
  },
}) => (
  <>
    <CarrierListContent className="background1" whiteTransition>
      <div className="lg:pt-44 pt-24 pb-10 w-full relative carriers-blk">
        <div className="w-full">
          <h1 className="lg:text-7xl md:text-5xl sm:text-4xl text-3xl text-blue font-medium tracking-tighter title1 relative text-center uppercase mb-8">
            {heading?.text}
          </h1>
          <p className="text-xl text-blue text-center mx-auto mb-10">{sub_heading}</p>
          {chunk(items, Math.ceil(items.length / chunks))?.map((list, index) => (
            <LogosSlider key={index} className="border-4" items={list} />
          ))}
        </div>
      </div>
    </CarrierListContent>
  </>
);

export default CarrierListHero;

export const query = graphql`
  fragment carriesListHeroSlice on PrismicCarriersListPageDataBodyHero {
    id
    slice_type
    primary {
    sub_heading
    heading {
        text
        html
    }
    }
    items {
        logo {
            alt
            fluid(maxWidth: 180, maxHeight: 60) {
                srcSet
                srcSetWebp
              }
        }
    }
  }
`
