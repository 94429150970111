import React from "react"
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react"
import { components } from "../components/CarriersList"
import Layout from "../components/layout"
import SEO from "../components/seo"


const CarriersLisPage = (props) => {
  const { data } = props
  if (!data) return null  
  const pageData = data.prismicCarriersListPage
  const page = pageData.data || {}
  return (
    <Layout theme={props.pageContext.header_theme && props.pageContext.header_theme}>
      <SEO
        context={props.pageContext}
        cpath={props.location.pathname}
      />
      <SliceZone
        slices={page.body}
        components={components}
        context={pageData}
        defaultComponent={() => null}
      />    
    </Layout>
  );
};

export default CarriersLisPage;

export const query = graphql`
  query carriesListQuery($uid: String!){
    prismicCarriersListPage(uid: {eq: $uid}) {
        uid
        id
        type
        data{
            body{
                ... on PrismicSliceType {
                  slice_type
                }
                ...carriesListHeroSlice
                ...carriesListFaqSlice
            }
        }
    }
  }
`
